// TripDetail.js
import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback
} from 'react';
import {
  collection,
  onSnapshot,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  getDoc
} from 'firebase/firestore';
import { db } from '../../firebase';

import TripMap from './TripMap';
import TripPlaces from './TripPlaces';
import TripItinerary from './TripItinerary';

import { IoMenu } from 'react-icons/io5';

/** Make sure this import points to the refactored CSS location */
import '../../styles/TripDetail/TripDetail.css';

function getCategoryFromTypes(types) {
  if (!types) return 'Others';
  const lowerTypes = types.map(t => t.toLowerCase());
  if (lowerTypes.some(t => ['tourist_attraction', 'museum', 'park', 'zoo', 'art_gallery', 'point_of_interest'].includes(t))) {
    return 'Attractions';
  }
  if (lowerTypes.some(t => ['restaurant', 'bar', 'cafe', 'food'].includes(t))) {
    return 'Food';
  }
  if (lowerTypes.some(t => ['lodging', 'hotel'].includes(t))) {
    return 'Accommodations';
  }
  return 'Others';
}

function TripDetail({ trip, selectedPlaceId, onSelectPlace, isMobile, onMenuClick }) {
  const { id: tripId, lat, lng, startDate, endDate } = trip;

  // Places
  const [places, setPlaces] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Category collapsible states
  const [collapsed, setCollapsed] = useState({
    Attractions: false,
    Food: false,
    Accommodations: false,
    Others: false
  });

  // Notes
  const [notesPlaceId, setNotesPlaceId] = useState(null);
  const [tempNotes, setTempNotes] = useState('');
  const [isEditingNotes, setIsEditingNotes] = useState(false);

  // Itinerary
  const [itinerary, setItinerary] = useState([]);
  const [showItinerary, setShowItinerary] = useState(false);

  // Google Maps / Autocomplete
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  const center = useMemo(() => ({ lat, lng }), [lat, lng]);

  // For bounding Autocomplete
  const bounds = useMemo(() => {
    const delta = 0.1;
    return new window.google.maps.LatLngBounds(
      { lat: lat - delta, lng: lng - delta },
      { lat: lat + delta, lng: lng + delta }
    );
  }, [lat, lng]);

  // Resizing states
  const [mapHeight, setMapHeight] = useState(window.innerHeight * 0.5);
  const isResizingRef = useRef(false);

  // ----- Fetch places -----
  useEffect(() => {
    const colRef = collection(db, "trips", tripId, "places");
    const unsub = onSnapshot(colRef, (snapshot) => {
      const placeData = snapshot.docs.map(d => ({ id: d.id, ...d.data() }));
      setPlaces(placeData);
    });
    return () => unsub();
  }, [tripId]);

  // ----- Load itinerary -----
  useEffect(() => {
    const loadItinerary = async () => {
      const tripRef = doc(db, "trips", tripId);
      const snapshot = await getDoc(tripRef);
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (data.itinerary && Array.isArray(data.itinerary)) {
          setItinerary(data.itinerary);
        } else {
          setItinerary([]);
        }
      }
    };
    loadItinerary();
  }, [tripId]);

  // ----- Prune itinerary if places removed -----
  useEffect(() => {
    if (!places || places.length === 0) {
      // If the new place list is empty, remove all places from itinerary
      if (itinerary.some(day => (day.places && day.places.length > 0))) {
        const cleared = itinerary.map(day => ({ ...day, places: [] }));
        setItinerary(cleared);
        updateDoc(doc(db, "trips", tripId), { itinerary: cleared });
      }
      return;
    }

    // Build a set of place IDs that still exist
    const validIds = new Set(places.map(p => p.id));
    let changed = false;
    const newItinerary = itinerary.map(dayObj => {
      const filteredPlaces = (dayObj.places || []).filter(p => validIds.has(p.id));
      if (filteredPlaces.length !== (dayObj.places || []).length) {
        changed = true;
      }
      return { ...dayObj, places: filteredPlaces };
    });

    if (changed) {
      setItinerary(newItinerary);
      updateDoc(doc(db, "trips", tripId), { itinerary: newItinerary });
    }
  }, [places, itinerary, tripId]);

  // ----- onPlaceChanged (Autocomplete) -----
  const onPlaceChanged = async () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry && place.geometry.location) {
        const placeLat = place.geometry.location.lat();
        const placeLng = place.geometry.location.lng();
        const category = getCategoryFromTypes(place.types || []);
        await addDoc(collection(db, "trips", tripId, "places"), {
          name: place.name || place.formatted_address,
          lat: placeLat,
          lng: placeLng,
          category
        });
        setInputValue('');
      }
    }
  };

  // ----- Delete place from place list -----
  const handleDeletePlace = async (placeId) => {
    await deleteDoc(doc(db, "trips", tripId, "places", placeId));
    if (selectedPlaceId === placeId) onSelectPlace(null);
  };

  // ----- Category collapse toggling -----
  const handleCategoryClick = (cat) => {
    setCollapsed(prev => ({ ...prev, [cat]: !prev[cat] }));
  };

  // ----- Group places by category -----
  const categoryPlaces = useMemo(() => {
    const catMap = {
      Attractions: [],
      Food: [],
      Accommodations: [],
      Others: []
    };
    places.forEach(p => {
      catMap[p.category || 'Others'].push(p);
    });
    return catMap;
  }, [places]);

  // ----- Scroll to selected place in the list -----
  useEffect(() => {
    if (selectedPlaceId) {
      const el = document.querySelector(`li[data-id="${selectedPlaceId}"]`);
      if (el && el.scrollIntoView) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedPlaceId]);

  // ----- Map interactions -----
  const handleMapClick = () => {
    onSelectPlace(null);
  };
  const onMarkerClick = (place) => {
    onSelectPlace(place.id);
  };
  const getMarkerIcon = (placeId) => {
    if (selectedPlaceId === placeId) {
      return "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
    }
    return "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
  };

  // ----- Notes -----
  const hasNotes = (place) => place.notes && place.notes.trim().length > 0;
  const toggleNotes = (place) => {
    if (notesPlaceId === place.id) {
      if (!isEditingNotes) {
        setNotesPlaceId(null);
      }
    } else {
      setNotesPlaceId(place.id);
      setTempNotes(place.notes || '');
      setIsEditingNotes(false);
    }
  };
  const saveNotes = async () => {
    if (!notesPlaceId) return;
    const placeRef = doc(db, "trips", tripId, "places", notesPlaceId);
    await updateDoc(placeRef, { notes: tempNotes });
    setIsEditingNotes(false);
  };
  const startEditingNotes = () => {
    setIsEditingNotes(true);
  };

  // ----- Must-Go -----
  const toggleMustGo = async (place) => {
    const placeRef = doc(db, "trips", tripId, "places", place.id);
    await updateDoc(placeRef, { mustGo: !place.mustGo });
  };
  const isMustGo = (place) => place.mustGo === true;

  // ----- Helper: get trip days -----
  function getTripDays(start, end) {
    if (!start || !end) return [];
    const startObj = new Date(start);
    const endObj = new Date(end);
    if (isNaN(startObj) || isNaN(endObj) || startObj > endObj) return [];
    const days = [];
    let current = new Date(startObj);
    while (current <= endObj) {
      days.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return days;
  }

  // ----- Show itinerary (create empty if none) -----
  const handleShowItinerary = async () => {
    if (itinerary.length === 0) {
      const tripRef = doc(db, "trips", tripId);
      const snapshot = await getDoc(tripRef);
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (data.itinerary && Array.isArray(data.itinerary) && data.itinerary.length > 0) {
          setItinerary(data.itinerary);
        } else {
          const days = getTripDays(startDate, endDate);
          const emptyItinerary = days.map(d => ({
            date: d.toISOString().split('T')[0],
            places: []
          }));
          setItinerary(emptyItinerary);
          await updateDoc(tripRef, { itinerary: emptyItinerary });
        }
      }
    }
    setShowItinerary(true);
  };
  const handleHideItinerary = () => {
    setShowItinerary(false);
  };

  // ----- Check if place is in itinerary -----
  const isInItinerary = (placeId) => {
    return itinerary.some(dayObj =>
      dayObj.places && dayObj.places.some(p => p.id === placeId)
    );
  };

  // ----- onItineraryChange => commit to Firestore -----
  const onItineraryChange = async (newItinerary) => {
    setItinerary(newItinerary);
    await updateDoc(doc(db, "trips", tripId), { itinerary: newItinerary });
  };

  // ----- Resizing logic -----
  const startResizing = useCallback((e) => {
    isResizingRef.current = true;
    e.preventDefault();
  }, []);
  const stopResizing = useCallback(() => {
    isResizingRef.current = false;
  }, []);
  const resize = useCallback((e) => {
    if (!isResizingRef.current) return;
    const newHeight = e.clientY;
    const minHeight = 100;
    const maxHeight = window.innerHeight - 100;
    if (newHeight > minHeight && newHeight < maxHeight) {
      setMapHeight(newHeight);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  const placesContainerHeight = `calc(100vh - ${mapHeight}px)`;

  return (
    <div className="trip-detail-container">
      {isMobile && onMenuClick && (
        <button className="menu-btn" onClick={onMenuClick}>
          <IoMenu size={24} />
        </button>
      )}

      <div className="map-container" style={{ height: mapHeight }}>
        <TripMap
          center={center}
          places={places}
          selectedPlaceId={selectedPlaceId}
          onMarkerClick={onMarkerClick}
          handleMapClick={handleMapClick}
          getMarkerIcon={getMarkerIcon}
          mapRef={mapRef}
        />
      </div>

      <div className="resizer" onMouseDown={startResizing}></div>

      <div
        className={`places-container ${showItinerary ? 'with-itinerary' : ''}`}
        style={{ height: placesContainerHeight }}
      >
        <TripPlaces
          bounds={bounds}
          autocompleteRef={autocompleteRef}
          inputValue={inputValue}
          setInputValue={setInputValue}
          onPlaceChanged={onPlaceChanged}
          places={places}
          selectedPlaceId={selectedPlaceId}
          onSelectPlace={onSelectPlace}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          handleCategoryClick={handleCategoryClick}
          categoryPlaces={categoryPlaces}
          handleDeletePlace={handleDeletePlace}
          toggleMustGo={toggleMustGo}
          isMustGo={isMustGo}
          notesPlaceId={notesPlaceId}
          toggleNotes={toggleNotes}
          tempNotes={tempNotes}
          setTempNotes={setTempNotes}
          saveNotes={saveNotes}
          isEditingNotes={isEditingNotes}
          startEditingNotes={startEditingNotes}
          hasNotes={hasNotes}
          showItinerary={showItinerary}
          onMenuClick={onMenuClick}
          isMobile={isMobile}
          isInItinerary={isInItinerary}
        />

        {showItinerary && (
          <TripItinerary
            itinerary={itinerary}
            onItineraryChange={onItineraryChange}
            handleHideItinerary={handleHideItinerary}
            places={places}
          />
        )}

        {!showItinerary && (
          <button
            className="itinerary-toggle-btn"
            onClick={handleShowItinerary}
          >
            Show itinerary
          </button>
        )}
      </div>
    </div>
  );
}

export default TripDetail;
