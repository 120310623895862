import React, { useState, useMemo } from 'react';
import { IoPencil, IoTrash, IoPeople } from 'react-icons/io5';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import '../styles/TripList.css';

function formatDate(dateStr) {
  if (!dateStr) return '';
  const parts = dateStr.split('-');
  if (parts.length === 3) {
    const [yyyy, mm, dd] = parts;
    return `${dd}/${mm}/${yyyy}`;
  }
  return dateStr;
}

function formatTripDates(startDate, endDate) {
  const sd = startDate ? formatDate(startDate) : '';
  const ed = endDate ? formatDate(endDate) : '';

  if (sd && ed) {
    return `${sd} - ${ed}`;
  } else if (sd && !ed) {
    return `${sd} -`;
  } else if (!sd && ed) {
    return `- ${ed}`;
  } else {
    return '';
  }
}

function parseDate(dateStr) {
  if (!dateStr) return null;
  // dateStr is assumed to be "YYYY-MM-DD"
  const [year, month, day] = dateStr.split('-').map((s) => parseInt(s, 10));
  if (!year || !month || !day) return null;
  return new Date(year, month - 1, day);
}

function isPastTrip(trip) {
  // If today's date is beyond the trip's end date
  // we consider it "Past". If no end date, we consider it not past.
  const endDateObj = parseDate(trip.endDate);
  if (!endDateObj) return false;
  const today = new Date();
  return today > endDateObj;
}

function tripSorter(a, b) {
  // Sort by start date earliest first; if same, then by end date earliest first.
  // If a or b has no start date, put it last.
  const aStart = parseDate(a.startDate);
  const bStart = parseDate(b.startDate);

  // If both null, they are equal
  if (!aStart && !bStart) return 0;
  // If only one is null, that one goes last
  if (!aStart && bStart) return 1;
  if (aStart && !bStart) return -1;

  // Now both have start dates
  if (aStart < bStart) return -1;
  if (aStart > bStart) return 1;

  // Start dates are the same, compare end dates
  const aEnd = parseDate(a.endDate);
  const bEnd = parseDate(b.endDate);

  // If both null, they are equal
  if (!aEnd && !bEnd) return 0;
  // If only one is null, that one goes last
  if (!aEnd && bEnd) return 1;
  if (aEnd && !bEnd) return -1;

  if (aEnd < bEnd) return -1;
  if (aEnd > bEnd) return 1;
  return 0;
}

function TripList({
  trips,
  selectedTripId,
  onSelectTrip,
  onCreateNewTrip,
  onEditTrip,
  onDeleteTrip,
}) {
  // Separate trips into "Current / Upcoming" vs. "Past" based on today's date.
  const [collapsed, setCollapsed] = useState({
    current: false,
    past: false,
  });

  // Sort all trips first
  const sortedTrips = useMemo(() => {
    return [...trips].sort(tripSorter);
  }, [trips]);

  // Partition them into two arrays
  const [currentTrips, pastTrips] = useMemo(() => {
    const curr = [];
    const past = [];
    sortedTrips.forEach((t) => {
      if (isPastTrip(t)) {
        past.push(t);
      } else {
        curr.push(t);
      }
    });
    return [curr, past];
  }, [sortedTrips]);

  const handleCollapseToggle = (section) => {
    setCollapsed((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const renderTripItem = (trip) => {
    const dateStr = formatTripDates(trip.startDate, trip.endDate);
    return (
      <div
        key={trip.id}
        className={`trip-item ${selectedTripId === trip.id ? 'selected' : ''}`}
        onClick={() => onSelectTrip(trip.id)}
      >
        {trip.numPeople && (
          <div className="people-count">
            <IoPeople
              style={{ verticalAlign: 'middle', marginRight: '3px' }}
            />
            {trip.numPeople}
          </div>
        )}
        <div className="trip-info">
          <div className="trip-name">{trip.location}</div>
          {dateStr && <div className="trip-dates">{dateStr}</div>}
        </div>
        <div
          className="trip-icons"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className="icon-btn"
            onClick={() => onEditTrip(trip)}
          >
            <IoPencil />
          </button>
          <button
            className="icon-btn"
            onClick={() => onDeleteTrip(trip.id)}
          >
            <IoTrash />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="trip-list-container">
      <h2 style={{ margin: '20px 0', textAlign: 'center' }}>Trips</h2>

      {trips.length === 0 ? (
        <div className="no-trips">No trips planned yet!</div>
      ) : (
        <div className="trip-list-sections">
          {/* Current / Upcoming Trips */}
          <div className="trip-section">
            <div
              className="trip-section-header"
              onClick={() => handleCollapseToggle('current')}
            >
              <span>Current / Upcoming trips</span>
              {collapsed.current ? <IoChevronUp /> : <IoChevronDown />}
            </div>
            {!collapsed.current && (
              <div className="trip-list">
                {currentTrips.length === 0 ? (
                  <div className="no-trips-in-section">
                    No current or upcoming trips.
                  </div>
                ) : (
                  currentTrips.map((trip) => renderTripItem(trip))
                )}
              </div>
            )}
          </div>

          {/* Past Trips */}
          <div className="trip-section">
            <div
              className="trip-section-header"
              onClick={() => handleCollapseToggle('past')}
            >
              <span>Past trips</span>
              {collapsed.past ? <IoChevronUp /> : <IoChevronDown />}
            </div>
            {!collapsed.past && (
              <div className="trip-list">
                {pastTrips.length === 0 ? (
                  <div className="no-trips-in-section">No past trips.</div>
                ) : (
                  pastTrips.map((trip) => renderTripItem(trip))
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <button className="create-trip-btn" onClick={onCreateNewTrip}>
        Create new trip
      </button>
    </div>
  );
}

export default TripList;
