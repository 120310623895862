// TripMap.js
import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import '../../styles/TripDetail/TripMap.css'; // Import the map-specific CSS

function TripMap({
  center,
  places,
  selectedPlaceId,
  onMarkerClick,
  handleMapClick,
  getMarkerIcon,
  mapRef,
}) {
  return (
    <GoogleMap
      onLoad={(map) => (mapRef.current = map)}
      mapContainerStyle={{ width: '100%', height: '100%' }}
      center={center}
      zoom={12}
      onClick={handleMapClick}
      options={{ mapTypeControl: false, clickableIcons: false }}
    >
      {places.map((p) => (
        <Marker
          key={p.id}
          position={{ lat: p.lat, lng: p.lng }}
          onClick={() => onMarkerClick(p)}
          icon={getMarkerIcon(p.id)}
        />
      ))}
    </GoogleMap>
  );
}

export default TripMap;
