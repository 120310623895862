// TripPlaces.js
import React from 'react';
import { Autocomplete } from '@react-google-maps/api';
import {
  IoTrash, IoDocumentText, IoStar, IoStarOutline,
  IoChevronDown, IoChevronUp
} from 'react-icons/io5';
import '../../styles/TripDetail/TripPlaces.css';

const CATEGORIES = ["Attractions", "Food", "Accommodations", "Others"];

// Helper: convert any URLs in notes to actual links
function linkify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);
  return parts.map((part, i) => {
    if (urlRegex.test(part)) {
      return (
        <a key={i} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
}

function TripPlaces({
  bounds,
  autocompleteRef,
  inputValue,
  setInputValue,
  onPlaceChanged,
  places,
  selectedPlaceId,
  onSelectPlace,
  collapsed,
  setCollapsed,
  handleCategoryClick,
  categoryPlaces,
  handleDeletePlace,
  toggleMustGo,
  isMustGo,
  notesPlaceId,
  toggleNotes,
  tempNotes,
  setTempNotes,
  saveNotes,
  isEditingNotes,
  startEditingNotes,
  hasNotes,
  showItinerary,
  onMenuClick,
  isMobile,
  isInItinerary
}) {
  // If you'd like to maintain the old drag+drop for categories:
  const onDragStart = (e, placeId) => {
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/plain', placeId); // not strictly necessary, but consistent
  };
  const onDragOver = (e) => {
    e.preventDefault();
  };
  const onDropOnCategory = (e, category) => {
    e.preventDefault();
    const placeId = e.dataTransfer.getData('text/plain');
    // Logic in TripDetail or parent's callback to update category...
    // (In the final code, you'd pass a prop callback to handle that.)
  };

  return (
    <div className="places-list-container">
      {isMobile && onMenuClick && (
        <button className="menu-btn" onClick={onMenuClick}>
          {/* Some icon if you'd like */}
        </button>
      )}

      <div className="autocomplete-container">
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={onPlaceChanged}
          options={{
            bounds: bounds,
            strictBounds: false,
          }}
        >
          <input
            type="text"
            placeholder="Search for places"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              boxSizing: 'border-box',
              border: '1px solid #ccc',
              borderRadius: '3px',
              outline: 'none'
            }}
          />
        </Autocomplete>
      </div>

      <div className="category-list">
        {CATEGORIES.map((cat) => {
          const catPlaces = categoryPlaces[cat] || [];
          return (
            <div
              key={cat}
              className="category-section"
              onDragOver={onDragOver}
              onDrop={(e) => onDropOnCategory(e, cat)}
            >
              <div
                className="category-header"
                onClick={() => handleCategoryClick(cat)}
              >
                <span>{cat}</span>
                {collapsed[cat] ? <IoChevronUp /> : <IoChevronDown />}
              </div>
              {!collapsed[cat] && catPlaces.length > 0 && (
                <ul
                  className="places-list"
                  onDragOver={onDragOver}
                  onDrop={(e) => onDropOnCategory(e, cat)}
                >
                  {catPlaces.map((p) => {
                    const showNotes = notesPlaceId === p.id;
                    return (
                      <li
                        key={p.id}
                        data-id={p.id}
                        className={`
                          ${selectedPlaceId === p.id ? 'selected-place' : ''} 
                          ${isInItinerary(p.id) ? 'in-itinerary' : ''}
                        `}
                        draggable={true}
                        onDragStart={(e) => onDragStart(e, p.id)}
                        onClick={() => onSelectPlace(p.id)}
                      >
                        <div className="place-row-top">
                          <div className="place-info">
                            <div className="place-title-row">
                              <div className="place-name">{p.name}</div>
                              <button
                                className={`icon-btn notes-icon ${hasNotes(p) ? 'has-notes' : 'no-notes'}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleNotes(p);
                                }}
                              >
                                <IoDocumentText />
                              </button>
                            </div>
                            {(p.startDate || p.endDate) && (
                              <div className="place-dates">
                                {p.startDate && p.endDate
                                  ? `${p.startDate} - ${p.endDate}`
                                  : p.startDate
                                    ? `${p.startDate} -`
                                    : `- ${p.endDate}`}
                              </div>
                            )}
                          </div>
                          <div className="place-actions">
                            <button
                              className="icon-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleMustGo(p);
                              }}
                            >
                              {isMustGo(p) ? (
                                <IoStar style={{ color: '#f5d742' }} />
                              ) : (
                                <IoStarOutline />
                              )}
                            </button>
                            <button
                              className="icon-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeletePlace(p.id);
                              }}
                            >
                              <IoTrash />
                            </button>
                          </div>
                        </div>

                        {showNotes && (
                          <div className="inline-notes" onClick={(e) => e.stopPropagation()}>
                            {isEditingNotes ? (
                              <>
                                <textarea
                                  className="notes-textarea"
                                  value={tempNotes}
                                  onChange={(e) => setTempNotes(e.target.value)}
                                />
                                <button className="save-notes-btn" onClick={saveNotes}>
                                  Save
                                </button>
                              </>
                            ) : (
                              <div className="notes-content" onClick={startEditingNotes}>
                                {tempNotes.trim().length > 0
                                  ? linkify(tempNotes)
                                  : 'No notes yet. Click to edit.'}
                              </div>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
              {!collapsed[cat] && catPlaces.length === 0 && (
                <div className="no-places-msg">No places added</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TripPlaces;
