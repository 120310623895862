// TripItinerary.js
import React, { useState, useRef } from 'react';
import { IoTrash } from 'react-icons/io5';
import '../../styles/TripDetail/TripItinerary.css';

/**
 * Reorder an array by removing the item at fromIndex
 * and inserting it at toIndex.
 */
function arrayReorder(arr, fromIndex, toIndex) {
  const newArr = [...arr];
  const [movedItem] = newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, movedItem);
  return newArr;
}

/**
 * Remove an item from one array and insert into another array at a position.
 */
function moveItemBetweenArrays(sourceArr, targetArr, fromIndex, toIndex) {
  const newSource = [...sourceArr];
  const newTarget = [...targetArr];
  const [movedItem] = newSource.splice(fromIndex, 1);
  newTarget.splice(toIndex, 0, movedItem);
  return { newSource, newTarget };
}

function TripItinerary({
  itinerary,            // array of { date: string, places: Place[] }
  onItineraryChange,    // callback(newItinerary)
  handleHideItinerary,
  places                // all places from Firestore, if needed
}) {
  // Keep our own local copy so we can reorder in real-time:
  const [tempItinerary, setTempItinerary] = useState(itinerary);

  // Track what item is being dragged:
  const draggingItemRef = useRef({
    dayIndex: null,    // from which day index?
    itemIndex: null,   // from which index in the day array?
    placeId: null
  });

  // Track if we performed a valid drop:
  const didDropRef = useRef(false);

  // Start drag
  const handleDragStart = (dayIndex, itemIndex, placeId, e) => {
    draggingItemRef.current = { dayIndex, itemIndex, placeId };
    didDropRef.current = false;
    e.dataTransfer.effectAllowed = 'move';
  };

  // End drag
  const handleDragEnd = () => {
    // If user never dropped on a valid item or day container,
    // remove it from the original location
    if (!didDropRef.current) {
      const { dayIndex, itemIndex } = draggingItemRef.current;
      if (dayIndex !== null && itemIndex !== null) {
        const newTemp = [...tempItinerary];
        const dayPlaces = [...newTemp[dayIndex].places];
        dayPlaces.splice(itemIndex, 1);
        newTemp[dayIndex].places = dayPlaces;
        setTempItinerary(newTemp);
      }
    }
    // Commit changes
    onItineraryChange(tempItinerary);

    // Reset
    draggingItemRef.current = { dayIndex: null, itemIndex: null, placeId: null };
  };

  // When we enter another item:
  const handleDragEnterItem = (targetDayIndex, targetItemIndex) => {
    const { dayIndex: sourceDayIndex, itemIndex: sourceItemIndex, placeId } = draggingItemRef.current;
    if (placeId == null) return;

    // If same day => reorder
    if (targetDayIndex === sourceDayIndex) {
      if (targetItemIndex !== sourceItemIndex) {
        const newTemp = [...tempItinerary];
        const oldPlaces = [...newTemp[sourceDayIndex].places];
        const reordered = arrayReorder(oldPlaces, sourceItemIndex, targetItemIndex);
        newTemp[sourceDayIndex].places = reordered;
        setTempItinerary(newTemp);

        // Update the itemIndex
        draggingItemRef.current.itemIndex = targetItemIndex;
      }
    } else {
      // Different day => move item between arrays
      const newTemp = [...tempItinerary];
      const sourcePlaces = [...newTemp[sourceDayIndex].places];
      const targetPlaces = [...newTemp[targetDayIndex].places];

      const { newSource, newTarget } = moveItemBetweenArrays(
        sourcePlaces,
        targetPlaces,
        sourceItemIndex,
        targetItemIndex
      );
      newTemp[sourceDayIndex].places = newSource;
      newTemp[targetDayIndex].places = newTarget;
      setTempItinerary(newTemp);

      // Update dayIndex/itemIndex
      draggingItemRef.current.dayIndex = targetDayIndex;
      draggingItemRef.current.itemIndex = targetItemIndex;
    }
    didDropRef.current = true;
  };

  // If user drops on the day container (not on a specific item), we put it at end
  const handleDropOnDayFooter = (targetDayIndex) => {
    const { dayIndex: sourceDayIndex, itemIndex: sourceItemIndex, placeId } = draggingItemRef.current;
    if (placeId == null) return;

    const newTemp = [...tempItinerary];

    if (targetDayIndex === sourceDayIndex) {
      // same day => move item to the end
      const dayPlaces = [...newTemp[sourceDayIndex].places];
      const [moved] = dayPlaces.splice(sourceItemIndex, 1);
      dayPlaces.push(moved);
      newTemp[sourceDayIndex].places = dayPlaces;
      draggingItemRef.current.itemIndex = dayPlaces.length - 1;
    } else {
      // cross-day => remove from source, add to target end
      const sourcePlaces = [...newTemp[sourceDayIndex].places];
      const targetPlaces = [...newTemp[targetDayIndex].places];
      const [moved] = sourcePlaces.splice(sourceItemIndex, 1);
      targetPlaces.push(moved);
      newTemp[sourceDayIndex].places = sourcePlaces;
      newTemp[targetDayIndex].places = targetPlaces;
      draggingItemRef.current.dayIndex = targetDayIndex;
      draggingItemRef.current.itemIndex = targetPlaces.length - 1;
    }
    setTempItinerary(newTemp);
    didDropRef.current = true;
  };

  // Remove place from a specific day
  const onRemovePlaceFromItinerary = (dayIndex, placeId) => {
    const newTemp = [...tempItinerary];
    const oldPlaces = [...newTemp[dayIndex].places];
    newTemp[dayIndex].places = oldPlaces.filter(p => p.id !== placeId);
    setTempItinerary(newTemp);
    onItineraryChange(newTemp); // commit right away
  };

  return (
    <div className="itinerary-container">
      <h3>Itinerary</h3>
      {tempItinerary.length === 0 ? (
        <div>No days available.</div>
      ) : (
        <div className="itinerary-day-list">
          {tempItinerary.map((day, dayIdx) => (
            <div
              key={day.date}
              className="itinerary-day-section"
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                // Only do footer logic if not dropping on a child
                if (e.target === e.currentTarget) {
                  handleDropOnDayFooter(dayIdx);
                }
              }}
            >
              <div className="itinerary-day-header">{day.date}</div>
              {(!day.places || day.places.length === 0) ? (
                <div className="no-places-msg">No places assigned</div>
              ) : (
                <ul className="itinerary-day-places">
                  {day.places.map((p, itemIdx) => (
                    <li
                      key={p.id}
                      className="itinerary-place-item"
                      draggable
                      onDragStart={(e) => handleDragStart(dayIdx, itemIdx, p.id, e)}
                      onDragEnd={handleDragEnd}
                      onDragEnter={() => handleDragEnterItem(dayIdx, itemIdx)}
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => {
                        // Stop propagation so day container doesn't handle it
                        e.preventDefault();
                        e.stopPropagation();
                        // We already do reordering onDragEnter, so here
                        // we can simply mark that a drop happened:
                        didDropRef.current = true;
                      }}
                    >
                      <span className="itinerary-place-name">{p.name}</span>
                      <button
                        className="itinerary-place-remove"
                        onClick={() => onRemovePlaceFromItinerary(dayIdx, p.id)}
                      >
                        <IoTrash />
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      )}
      <button className="itinerary-toggle-btn" onClick={handleHideItinerary}>
        Hide itinerary
      </button>
    </div>
  );
}

export default TripItinerary;
